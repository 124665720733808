import React from "react";
import { Link } from "gatsby";
import styled from "@emotion/styled";

import Layout from "@components/Layout";
import Section from "@components/Section";
import Headings from "@components/Headings";
import Paragraph from "@components/Paragraph";

export default () => {
  return (
    <>
      <Layout>
        <Section>
          <Headings.h1>Soon</Headings.h1>
        </Section>
      </Layout>
    </>
  );
};
